import React from 'react'
import Image from 'next/image'
function Client({happyClients}:any) {

  return (
    <section className="client_section gradient_bg white">
    <div className="client_left fit_img" data-aos="fade" data-aos-delay="100">
        <Image src={happyClients?.image?.sourceUrl} width={647} height={660} alt={happyClients?.image?.altText} loading="lazy" />
    </div>
    <div className="container left_edge sections">
        <div className="row">
            <div className="col-lg-8 offset-lg-4">
                <div className="client_right">
                    <div className="sections_title left">
                        <h2 data-aos="fade" data-aos-delay="200">{happyClients?.title}</h2>
                        <p data-aos="fade" data-aos-delay="300">{happyClients?.description}</p>
                    </div>
                    <div className="row">
                        {
                            happyClients?.clientsLogos && happyClients?.clientsLogos.map((cl:any,index:any)=>{
                                return (
                                    <div key={index} className="col-md-3 col-sm-4 col-6" data-aos="fade" data-aos-delay="100">
                                        {/* <Link href={cl.link} className="client_logo" target='_blank'>
                                            <Image src={cl?.clientLogo?.sourceUrl} width={120} height={42} alt={cl?.clientLogo?.altText}  loading="lazy"/>
                                        </Link> */}
                                        <div  className="client_logo">
                                            <Image src={cl?.clientLogo?.sourceUrl} width={120} height={42} alt={cl?.clientLogo?.altText}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Client
